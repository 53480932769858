import { createSlice,createEntityAdapter, createAsyncThunk } from "@reduxjs/toolkit";



const businessSlice = createSlice({
    name: "business",
    initialState: {},
    reducers: {},
    extraReducers: {}
})


export default businessSlice.reducer;