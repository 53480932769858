import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./magicball.module.css";
import { BiHomeAlt, BiRegistered } from "react-icons/bi";
import { MdPermContactCalendar } from "react-icons/md";
import { GiCrossedSwords } from "react-icons/gi";
import { AiOutlineRead } from "react-icons/ai";

const MagicBall = () => {
  const navigate = useNavigate();

  const handleNavigate = (paramsUrl) => {
    navigate(`/${paramsUrl}`);
  };

  return (
    <div className={`${styles.magic_box}`}>
      <BiHomeAlt
        onClick={() => {
          handleNavigate("");
        }}
        className={`${styles.magicball_icons}`}
        title="Home Page"
      />

      <MdPermContactCalendar
        onClick={() => {
          handleNavigate("contactus");
        }}
        className={`${styles.magicball_icons}`}
        title="Contact Us"
      />

      <BiRegistered
        onClick={() => {
          handleNavigate("register");
        }}
        className={`${styles.magicball_icons}`}
        title="Sign In"
      />

      <AiOutlineRead
        onClick={() => {
          handleNavigate("tutorials");
        }}
        className={`${styles.magicball_icons}`}
        title="Tutorials"
      />

      <GiCrossedSwords
        onClick={() => {
          handleNavigate("workspace");
        }}
        className={`${styles.magicball_icons}`}
        title="Challenge"
      />
    </div>
  );
};

export default MagicBall;
