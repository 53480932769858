import { configureStore } from '@reduxjs/toolkit'
import usersReducer from '../features/redux/usersSlice'
import challengesReducer from '../features/redux/challengesSlice'
import alghoritmsReducer from '../features/redux/alghoritmsSlice'
import emailsReducer from '../features/redux/emailsSlice'
import premiumsReducer from '../features/redux/premiumsSlice'
import businessReducer from '../features/redux/businessSlice'
import startersReducer from '../features/redux/startersSlice'
import freeusersReducer from '../features/redux/freeusersSlice'
import leadersReducer from '../features/redux/leadersSlice'
import coursesReducer from '../features/redux/coursesSlice'

export const store = configureStore({
  reducer: {
    users: usersReducer,
    emails: emailsReducer,
    challenges: challengesReducer,
    alghoritms: alghoritmsReducer,
    premiums: premiumsReducer,
    business: businessReducer,
    starters: startersReducer,
    freeusers: freeusersReducer,
    leaders: leadersReducer,
    courses: coursesReducer,
  },
})
