import React from 'react';
import StartUpNavigation from '../../startup/StartUpNavigation';

const HireFreelancer = () => {
  return (
    <div>
    <StartUpNavigation />
  </div>
  )
}

export default HireFreelancer