import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";

const freeusersSlice = createSlice({
  name: "freeusers",
  initialState: {},
  reducers: {},
  extraReducers: {},
});

export default freeusersSlice.reducer;
