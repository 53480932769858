import React from "react";
import UserProfileLeaderBoardItem from "./UserProfileLeaderBoardItem";
import styles from "./workspace.module.css";


const ContentLeaderBoard = () => {
  return (
    <div className={`${styles.contentcontent}`}>
      <div className={`${styles.code_random_container} align-items-start`}>
        <UserProfileLeaderBoardItem />
        <UserProfileLeaderBoardItem />
        <UserProfileLeaderBoardItem />
        <UserProfileLeaderBoardItem />
        <UserProfileLeaderBoardItem />
        <UserProfileLeaderBoardItem />
        <UserProfileLeaderBoardItem />
        <UserProfileLeaderBoardItem />
        <UserProfileLeaderBoardItem />
      </div>
    </div>
  );
};

export default ContentLeaderBoard;
