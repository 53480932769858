import React from "react";
import styles from "./workspace.module.css";




const ContentDashboard = () => {
  return (
    <div className={`${styles.contentcontent}`}>
      <div className={`${styles.code_random_container}`}>
   
      </div>
    </div>
  );
};

export default ContentDashboard;
