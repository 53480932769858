import React, { useState } from "react";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import styles from "./workspace.module.css";

function UncontrolledBoard() {
  const [board, setBoard] = useState({
    columns: [
      {
        id: 1,
        title: "Backlog",
        cards: [
          {
            id: 1,
            title: "Card title 1",
            description: "Card content",
          },
          {
            id: 2,
            title: "Card title 2",
            description: "Card content",
          },
          {
            id: 3,
            title: "Card title 3",
            description: "Card content",
          },
        ],
      },
      {
        id: 2,
        title: "Doing",
        cards: [
          {
            id: 9,
            title: "Card title 9",
            description: "Card content",
          },
        ],
      },
      {
        id: 3,
        title: "Q&A",
        cards: [
          {
            id: 10,
            title: "Card title 10",
            description: "Card content",
          },
          {
            id: 11,
            title: "Card title 11",
            description: "Card content",
          },
        ],
      },
      {
        id: 4,
        title: "Production",
        cards: [
          {
            id: 12,
            title: "Card title 12",
            description: "Card content",
          },
          {
            id: 13,
            title: "Card title 13",
            description: "Card content",
          },
        ],
      },
      {
        id: 5,
        title: "Done",
        cards: [
          {
            id: 14,
            title: "Card title 14",
            description: "Card content",
          },
        ],
      },
      {
        id: 6,
        title: "Archive",
        cards: [
          {
            id: 15,
            title: "Card title 15",
            description: "Card content",
          },
        ],
      },
      {
        id: 7,
        title: "Backlog",
        cards: [
          {
            id: 1,
            title: "Card title 1",
            description: "Card content",
          },
          {
            id: 2,
            title: "Card title 2",
            description: "Card content",
          },
          {
            id: 3,
            title: "Card title 3",
            description: "Card content",
          },
        ],
      },
      {
        id: 8,
        title: "Doing",
        cards: [
          {
            id: 9,
            title: "Card title 9",
            description: "Card content",
          },
        ],
      },
      {
        id: 9,
        title: "Q&A",
        cards: [
          {
            id: 10,
            title: "Card title 10",
            description: "Card content",
          },
          {
            id: 11,
            title: "Card title 11",
            description: "Card content",
          },
        ],
      },
      {
        id: 10,
        title: "Production",
        cards: [
          {
            id: 12,
            title: "Card title 12",
            description: "Card content",
          },
          {
            id: 13,
            title: "Card title 13",
            description: "Card content",
          },
        ],
      },
      {
        id: 11,
        title: "Done",
        cards: [
          {
            id: 14,
            title: "Card title 14",
            description: "Card content",
          },
        ],
      },
      {
        id: 12,
        title: "Archive",
        cards: [
          {
            id: 15,
            title: "Card title 15",
            description: "Card content",
          },
        ],
      },
    ],
  });




  return (
    <div style={{display:"flex",flexWrap:"wrap"}}>
      <Board
        allowRemoveLane
        allowRenameColumn
        allowRemoveCard
        onLaneRemove={console.log}
        onCardRemove={console.log}
        onLaneRename={console.log}
        initialBoard={board}
        allowAddCard={{ on: "top" }}
        onNewCardConfirm={(draftCard) => ({
          id: new Date().getTime(),
          ...draftCard,
        })}
        onCardNew={console.log}
      />
    </div>
  );
}

const ContentTaskbar = () => {
  return (
    <div className={`${styles.contentcontent}`}>
      <div className={`${styles.task_container}`}>
        <UncontrolledBoard />
      </div>
    </div>
  );
};

export default ContentTaskbar;
