import React from 'react';
import StartUpNavigation from '../../startup/StartUpNavigation';

const FindWork = () => {
  return (
    <div>
      <StartUpNavigation />
    </div>
  )
}

export default FindWork