import React from 'react'
import StartUpNavigation from "../../startup/StartUpNavigation";

const FreelanceProjects = () => {
  return (
    <div>
      <StartUpNavigation />
    </div>
  )
}

export default FreelanceProjects