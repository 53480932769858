
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import StartUp from "../features/startup/StartUp";
import WorkSpace from "../features/workspace/WorkSpace";
import SignIn from "./../features/Pages/SignIn";
import SignUp from "./../features/Pages/SignUp";
import PasswordRecovery from "./../features/Pages/PasswordRecovery";
import ResetPassword from "./../features/Pages/ResetPassword";
import FindWork from "../features/common/Jobs/FindWork";
import FreelanceProjects from "../features/common/Jobs/FreelanceProjects";
import LeaderBoard from "../features/common/Leadership/LeaderBoard";
import Explore from "../features/common/Explore/Explore";
import Tutorials from "../features/common/Tutorials/Tutorials";
import CoursesDetail from "../features/common/Tutorials/CoursesDetail";
import HireFreelancer from "../features/common/Hire/HireFreelancer";
import CodeFace from "../features/common/CodeFace/CodeFace";
import NotFound from "./../features/common/routingsError/NotFound";
import ArticleList from "../features/common/More/ArticleList ";
import MagicBall from "../features/magic/MagicBall";
import ContactUs from "../features/common/ContactUs/ContactUs";

import PrivacyPolicy from "../features/Legal/PrivacyPolicy";
import CookiesPolicy from "../features/Legal/CookiesPolicy";
import TermsOfService from "../features/Legal/TermsOfService";
import LawEnforcement from "../features/Legal/LawEnforcement";
import CommunityGuidlines from "../features/Support/CommunityGuidlines";
import HelpCenter from "../features/Support/HelpCenter";
import SafetyCenter from "../features/Support/SafetyCenter";

import ChatAppReact from "./../features/common/Chat/ChatAppReact";

const Dashboard = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [navLight, setNavLight] = useState(false);

  const userDataSender = () => {
    return {
      user: {
        firstName,
        lastName,
        email,
        password,
      },
      setUser: {
        setFirstName,
        setLastName,
        setEmail,
        setPassword,
      },
    };
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 123) {
        setNavLight(true);
      } else {
        setNavLight(false);
      }
    });
  }, []);

  return (
    <div className="dashboard">
      <MagicBall />
      <ChatAppReact />

      <Routes>
        {/* HOME PAGE */}
        <Route path="/" element={<StartUp />} />

        {/* FORM REGISTER LOGIN RECOVER CONTACT US AND RESET */}
        <Route
          path="/register"
          element={
            <SignIn userDataSender={userDataSender} navLight={navLight} />
          }
        />
        <Route path="/login" element={<SignUp navLight={navLight} />} />
        <Route path="/recover" element={<PasswordRecovery />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/contactus" element={<ContactUs />} />

        {/* AFTER SIGN IN USER PROFILE */}
        <Route path="/workspace" element={<WorkSpace />} />

        {/* ROUTING FROM NAVIGATION BAR */}
        <Route path="/findwork" element={<FindWork />} />
        <Route path="/freelance" element={<FreelanceProjects />} />
        <Route path="/ranking" element={<LeaderBoard />} />
        <Route path="/tutorials" element={<Tutorials navLight={navLight} />} />
        <Route path="/tutorials/detail" element={<CoursesDetail />} />
        <Route path="/hireprogrammer" element={<HireFreelancer />} />
        <Route path="/aboutus" element={<CodeFace />} />
        <Route path="/explore" element={<Explore />} />

        {/* GET STARTED */}
        {/* LEARN MORE */}
        <Route path="/more" element={<ArticleList navLight={navLight} />} />

        {/* LEGAL */}
        <Route path="/cookiespolicy" element={<CookiesPolicy />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofservices" element={<TermsOfService />} />
        <Route path="/lawenforcement" element={<LawEnforcement />} />

        {/* SUPPORT */}
        <Route path="/communityguidlines" element={<CommunityGuidlines />} />
        <Route path="/helpcenter" element={<HelpCenter />} />
        <Route path="/safetycenter" element={<SafetyCenter />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default Dashboard;
