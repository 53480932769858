/* eslint-disable no-unused-vars */
import React from 'react';
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react';
import Dashboard from './Dashboard';


const App = () => {
  return (
     <ChakraProvider>
        <ColorModeProvider>
          <Dashboard />
        </ColorModeProvider>
     </ChakraProvider>
  )
}

export default App