/* eslint-disable react/prop-types */
import React, {useState} from "react";
import styles from "./tutorials.module.css";
import StartUpNavigation from "../../startup/StartUpNavigation";
import Footer from "./../Footer/SmallWithLogoLeft";
import CourseItem from "./CourseItem";
import ide from "./../../../features/assets/idte.png";
import TypeAnimation from "react-type-animation";

const courseCategories = [
  "Web Development",
  "Flutter",
  "React Native",
  "UI/UX",
  "Photoshop"
];

const Tutorials = ({navLight}) => {
  const rebderedCourseCategories = courseCategories.map(
    (courseCategory, index) => {
      return <li key={index}>{courseCategory}</li>;
    }
  )




  return (
    <div className={`${styles.tutorials_container}`}>
      <div className={`${styles.bg_image}`}>
        <StartUpNavigation navLight={navLight} />
        <h1>
          Choose <div></div> <span>Your Future</span> <div></div> Career
        </h1>
        <TypeAnimation
          cursor={true}
          sequence={[
            "Whether you’re getting new hires up to speed or training your people on the latest tech skills, CodeFace gives you a better way to do learning.",
            5000,
          ]}
          wrapper="p"
          repeat={Infinity}
        />
        <button>Explore now!</button>
        <div className={`${styles.tutorials_header_ide_container}`}>
          <img src={ide} alt="" />
        </div>
      </div>
      <div className={`${styles.explore_our_courses_container}`}>
        <div className={`${styles.courses_features_ui_control}`}>
          <h2>Explore Our Popular Courses</h2>
        </div>
        <ul>{rebderedCourseCategories}</ul>
        <div className={`${styles.courses_card_container}`}>
          <CourseItem title="Javascript Course for beginners (100 hours)" />
          <CourseItem title="Javascript Course for beginners (96 hours)" />
          <CourseItem title="Javascript Course for beginners (250 hours)" />
          <CourseItem title="Javascript Course for beginners (100 hours)" />
          <CourseItem title="Javascript Course for beginners (96 hours)" />
          <CourseItem title="Javascript Course for beginners (250 hours)" />
          <CourseItem title="Javascript Course for beginners (100 hours)" />
          <CourseItem title="Javascript Course for beginners (96 hours)" />
          <CourseItem title="Javascript Course for beginners (250 hours)" />
          <CourseItem title="Javascript Course for beginners (100 hours)" />
          <CourseItem title="Javascript Course for beginners (96 hours)" />
          <CourseItem title="Javascript Course for beginners (250 hours)" />
          <CourseItem title="Javascript Course for beginners (100 hours)" />
          <CourseItem title="Javascript Course for beginners (96 hours)" />
          <CourseItem title="Javascript Course for beginners (250 hours)" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Tutorials;
