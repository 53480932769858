import React from "react";
import styles from "./workspace.module.css";
import codeFace from "./../assets/logo2.png";
import { IoArrowUndoCircleSharp } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdContactSupport } from "react-icons/md";
import { BsCodeSlash, BsGem } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
} from "react-icons/fi";

import NavItem from "./NavItem";

const sideBarData = [
  { content: "Dashboard", url: "dashboard", icon: FiSettings },
  { content: "UI tools", url: "toolbox", icon: FiSettings },
  { content: "Taskbar", url: "taskbar", icon: FiSettings },
  { content: "LeaderBoard", url: "leaderboard", icon: FiCompass },
  { content: "Random Code", url: "randomcode", icon: FiStar },
  { content: "Arena", url: "arena", icon: FiHome },
  { content: "IDE", url: "codeeditor", icon: FiTrendingUp },
];

const SimpleSideBar = ({ max, setMax, handleWorkspaceComponentClick }) => {
  return (
    <div className={`${!max ? styles.sidebar : styles.sidebar_sm}`}>
      <div className="mb-4">
        {!max ? (
          <img src={codeFace} alt="codeface logo of company" width="160" />
        ) : (
          <BsCodeSlash />
        )}
        <IoArrowUndoCircleSharp onClick={() => setMax(!max)} />
      </div>
      <div>
        <form className={styles.sidebar_search_container}>
          <BsSearch className={styles.search_icon} />
          <input
            type="text"
            name="sidebar_search"
            placeholder="Search"
            className={styles.sidebar_search}
          />
        </form>
      </div>
      <ul className="my-4">
        {sideBarData.map((navData, index) => {
          return (
            <NavItem
              key={index}
              navData={navData}
              max={max}
              handleWorkspaceComponentClick={handleWorkspaceComponentClick}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default SimpleSideBar;

/*

<div
        className={`${
          !max ? styles.premium_container : styles.premium_container_sm
        }`}
      >
        {!max ? (
          <div className={`${styles.bg_image_content}`}>
            <span></span>

            <div className={`${styles.loader}`}>
              <div className={`${styles.loader_square}`}>C</div>
              <div className={`${styles.loader_square}`}>O</div>
              <div className={`${styles.loader_square}`}>D</div>
              <div className={`${styles.loader_square}`}>E</div>
              <div className={`${styles.loader_square}`}>F</div>
              <div className={`${styles.loader_square}`}>A</div>
              <div className={`${styles.loader_square}`}>C</div>
              <div className={`${styles.loader_square}`}>E</div>
            </div>

            <button className={`${styles.button} `}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
                <path d="m18 0 8 12 10-8-4 20H4L0 4l10 8 8-12z"></path>
              </svg>
              Unlock Pro
            </button>
          </div>
        ) : (
          <BsGem />
        )}
      </div> 


*/
