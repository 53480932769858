import React from "react";

const Message = ({user,message}) => {
  return (
    <div>
      <p>
        <strong>{user}</strong> says:
      </p>
      <p>{message}</p>
    </div>
  );
};

export default Message;
