import React from "react";
import { useParams, useSelector } from "react-router-dom";
import SimpleSideBar from "./SimpleSideBar";
import Nav from "./Nav";
import Content from "./Content";
import ContentArena from "./ContentArena";
import ContentDashboard from "./ContentDashboard";
import ContentLeaderBoard from "./ContentLeaderBoard";
import ContentRandomCode from "./ContentRandomCode";
import ContentTaskbar from "./ContentTaskbar";
import ContentUITools from "./ContentUITools";

import { createTheme } from "@uiw/codemirror-themes";
import { tags as t } from "@lezer/highlight";

import styles from "./workspace.module.css";

const themes = [
  "abcdef",
  "androidstudio",
  "bespin",
  "duotone",
  "dracula",
  "darcula",
  "eclipse",
  "github",
  "okaidia",
  "sublime",
];

const myTheme = createTheme({
  theme: "dark",
  settings: {
    background: "#202224",
    foreground: "#75baff",
    caret: "#fff",
    selection: "#036dd626",
    selectionMatch: "#036dd626",
    lineHighlight: "#8a91991a",
    gutterBackground: "#202224",
    gutterForeground: "#8a919966",
  },
  styles: [
    { tag: t.comment, color: "#787b8099" },
    { tag: t.variableName, color: "#F4CA16" },
    { tag: [t.string, t.special(t.brace)], color: "#F07427" },
    { tag: t.number, color: "#00DDFB" },
    { tag: t.bool, color: "#F07427" },
    { tag: t.null, color: "#00CCFE" },
    { tag: t.keyword, color: "#FDF5E6" },
    { tag: t.operator, color: "#FDF5E6" },
    { tag: t.className, color: "#FDF5E6" },
    { tag: t.definition(t.typeName), color: "#5c6166" },
    { tag: t.typeName, color: "#9457EB" },
    { tag: t.angleBracket, color: "#9457EB" },
    { tag: t.tagName, color: "#5c6166" },
    { tag: t.attributeName, color: "#5c6166" },
  ],
});

const WorkSpace = () => {
  const [max, setMax] = React.useState(false);
  const [url, setURL] = React.useState("");
  const [theme, setTheme] = React.useState("");

  const renderedThemes = themes.map((theme, index) => {
    return (
      <option key={index} value={theme}>
        {theme}
      </option>
    );
  });

  const handleWorkspaceComponentClick = (paramsContent) => {
    setURL(paramsContent);
  };

  return (
    <div
      className={`${!max ? styles.grid_container : styles.grid_container_sm}`}
    >
      <SimpleSideBar
        max={max}
        setMax={setMax}
        handleWorkspaceComponentClick={handleWorkspaceComponentClick}
      />
      <Nav />
      {url === "dashboard" ? (
        <ContentDashboard />
      ) : url === "toolbox" ? (
        <ContentUITools />
      ) : url === "taskbar" ? (
        <ContentTaskbar />
      ) : url === "leaderboard" ? (
        <ContentLeaderBoard />
      ) : url === "randomcode" ? (
        <ContentRandomCode />
      ) : url === "arena" ? (
        <ContentArena myTheme={theme && theme.length > 0 ? theme : myTheme} />
      ) : (
        <Content myTheme={myTheme} />
      )}
    </div>
  );
};

export default WorkSpace;
