import React from "react";
import styles from "./code.module.css";
import logo from "./../assets/svg/1.svg";
import { useNavigate } from "react-router-dom";

export const StartUpNavigation = ({ navLight }) => {
  const navigate = useNavigate();

  const handleRouting = (paramsRoute) => {
    navigate(paramsRoute);
  };

  return (
    <div
      className={`${
        !navLight
          ? styles.navigation_main_container
          : styles.navigation_main_container_light
      }`}
    >
      <img src={logo} alt="" onClick={() => handleRouting("/")} />
      <div>
        <p onClick={() => handleRouting("/tutorials")}>TUTORIALS</p>
        <p onClick={() => handleRouting("/workspace")}>ARENA</p>
        <p onClick={() => handleRouting("/")}>DOCS</p>
        <button onClick={() => handleRouting("/register")}>
          Start journey - IT'S FREE
        </button>
      </div>
    </div>
  );
};

export default StartUpNavigation;
