/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SmallWithLogoLeft from "../common/Footer/SmallWithLogoLeft";
import StartUpNavigation from "../startup/StartUpNavigation";
import styles from "./form.module.css";
import { BsGithub } from 'react-icons/bs'

const SignUp = ({ navLight }) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const isDisabled = [password, email].every(Boolean) && password.length >= 8;

  const handleRouting = (paramsUrl) => {
    navigate(paramsUrl);
  };

  return (
    <div>
      <StartUpNavigation navLight={navLight} />
      <div className={`${styles.signin_signup_container}`}>
        <form>
          <h1>Sign Up</h1>
          <p>
            Welcome to <span>CodeFace</span>
          </p>
          <div
            className={`${styles.form_input_container}  d-flex flex-column align-items-start`}
          >
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" placeholder="Name" />
          </div>
          <div
            className={`${styles.form_input_container}  d-flex flex-column align-items-start`}
          >
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Name"
            />
            <small>
              Your password must be 8 or more characters long and contain a mix
              upper & lower case letters, number ans symbol
            </small>
          </div>

          <button disabled={!isDisabled}>LOGIN</button>
          <button className={`${styles.github_button}`}>
                 <BsGithub />
          </button>
          <p>
            Not have an account?
            <span
              onClick={() => handleRouting("/register")}
              style={{ marginLeft: "10px", cursor: "pointer" }}
            >
              REGISTER
            </span>
          </p>
        </form>
      </div>
      <SmallWithLogoLeft />
    </div>
  );
};

export default SignUp;
