import React from "react";
import styles from "./workspace.module.css";
import { Avatar } from "@chakra-ui/react";

const Nav = () => {
  return (
    <div
      className={`${styles.navcontent} d-flex justify-content-end align-items-center`}
    >
      <div>
        <Avatar size="sm" name="Code Face" />
      </div>
    </div>
  );
};

export default Nav;
