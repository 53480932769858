import React from "react";
import LogsContainer from "./LogsContainer";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import styles from "./workspace.module.css";

const ContentArena = ({ myTheme }) => {
  const onChange = React.useCallback((value, viewUpdate) => {
    eval(value);
  }, []);

  return (
    <div className={`${styles.contentcontent_arena}`}>
      <div className={`${styles.code_area_home_body_arena}`}>
        <div className={`${styles.code_space_area}`}>
          <CodeMirror
            value="// Write your code here"
            height="625px"
            lazyLoadMode={true}
            theme={myTheme}
            extensions={[javascript({ jsx: true })]}
            onChange={onChange}
          />
        </div>
        <div className={`${styles.output_space_area}`}>
          <LogsContainer />
        </div>
      </div>

      <div className={`${styles.code_area_home_body_arena}`}>
        <div className={`${styles.code_space_area}`}>
          <CodeMirror
            value="// Write your code here"
            height="625px"
            lazyLoadMode={true}
            theme={myTheme}
            extensions={[javascript({ jsx: true })]}
            onChange={onChange}
          />
        </div>
        <div className={`${styles.output_space_area}`}>
          <LogsContainer />
        </div>
      </div>
    </div>
  );
};

export default ContentArena;
