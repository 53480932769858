import React from "react";
import { Link } from "react-router-dom";
import styles from "./workspace.module.css";

const NavItem = ({ navData, max, handleWorkspaceComponentClick }) => {
  const responsiveStyle = {
    marginRight: "0",
    fontSize: "1.5rem",
  };

  return (
    <li
      className={`${max ? styles.aside_item_resp : styles.aside_item}`}
      onClick={() => handleWorkspaceComponentClick(navData.url)}
    >
      <navData.icon style={max && responsiveStyle} />
      {!max && navData.content}
    </li>
  );
};

export default NavItem;
