import React from "react";
import styles from "./code.module.css";
import "react-alice-carousel/lib/alice-carousel.css";
import instagram from "./../assets/teamins.png";
import google from "./../assets/teamgoog.png";
import github from "./../assets/teamgit.png";

const ourTeam = [
  {
    name: "Ibrahim",
    surname: "Ibrahimli",
    username: "@ibrahim",
    projects: "4",

    image: "https://picsum.photos/160/160",
  },
  {
    name: "Ugurlu",
    surname: "Murquzov",
    username: "@ugurlu",
    projects: "8",

    image: "https://picsum.photos/160/160",
  },
  {
    name: "Nail",
    surname: "Ismayilov",
    username: "@nail",
    projects: "3",

    image: "https://picsum.photos/160/160",
  },
  {
    name: "Mehemmed",
    surname: "Serkerli",
    username: "@mehemmed",
    projects: "1",

    image: "https://picsum.photos/160/160",
  },
  {
    name: "Yusif",
    surname: "Hesenov",
    username: "@yusif",
    projects: "5",

    image: "https://picsum.photos/160/160",
  },
  {
    name: "Nabi",
    surname: "Huseynov",
    username: "@nabi",
    projects: "6",

    image: "https://picsum.photos/160/160",
  },
];

const StupidTeam = () => {
  const teamCards = ourTeam.map((team) => {
    return (
      <div className={styles.teamCard}>
        <div className={`${styles.team_card_leftSide}`}>
          <div className={`${styles.leftSide_inside}`}>
            <div>
              <img src={team.image} alt="team member" />
            </div>
          </div>

          <div className={styles.center_inside}>
            {/* <button>
              {" "}
              {team.name}&nbsp;
              {team.surname}
            </button> */}
          </div>
          <div className={`${styles.rightSide_inside}`}>
            <img src={instagram} alt="team-social-media-links" width="36" />
            <img src={google} alt="team-social-media-links" width="36" />
            <img src={github} alt="team-social-media-links" width="36" />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={`${styles.stupid_container}`}>
      <h2 className={`${styles.code_section_header_away_team}`}>
        CodeFace team <br /> support you in every condition
      </h2>
      <div>{teamCards}</div>
    </div>
  );
};

export default StupidTeam;
