import {createSlice,createEntityAdapter,createAsyncThunk} from '@reduxjs/toolkit';



const coursesSlice = createSlice({
    name: 'courses',
    initialState: {},
    reducers: {},
    extraReducers: {}
})



export default coursesSlice.reducer;