import React from "react";
import { AiOutlineSave, AiOutlineLike, AiOutlineComment } from "react-icons/ai";
import styles from "./workspace.module.css";

const RandomCodeItem = () => {
  return (
    <>
      <div className={`${styles.card_IDE}`}>
        <div className={`${styles.file_container}`}>
          <div className={`${styles.circle_icons}`}></div>
          <div className={`${styles.circle_icons}`}></div>
          <div className={`${styles.circle_icons}`}></div>
        </div>
        <div className={`${styles.main_view_container}`}>
          <p>Card Title</p>
        </div>
        <div className={`${styles.user_action_container}`}>
          <div>
            <AiOutlineLike />
            <AiOutlineComment />
          </div>
          <AiOutlineSave />
        </div>
      </div>
    </>
  );
};

export default RandomCodeItem;
