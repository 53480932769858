import React from "react";
import styles from "./workspace.module.css";
import RandomCodeItem from "./RandomCodeItem";

const ContentRandomCode = () => {
  return (
    <div className={`${styles.contentcontent}`}>
      <div className={`${styles.code_random_container}`}>
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
        <RandomCodeItem />
      </div>
    </div>
  );
};

export default ContentRandomCode;
