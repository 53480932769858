import { createSlice,createEntityAdapter, createAsyncThunk } from "@reduxjs/toolkit";



const startersSlice = createSlice({
    name: "starters",
    initialState: {},
    reducers: {},
    extraReducers: {}
})


export default startersSlice.reducer;