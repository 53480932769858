/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styles from "./workspace.module.css";
import LogsContainer from "./LogsContainer";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import ParticlesBg from "particles-bg";


const Content = ({ myTheme }) => {
  
  const onChange = React.useCallback((value, viewUpdate) => {
    eval(value);
  }, []);

  return (
    <div className={`${styles.contentcontent}`}>
      {/* home section */}
      <div className={`${styles.code_area_home}`}>
        <div className={`${styles.code_area_home_body}`}>
          <div className={`${styles.code_space_area}`}>
            <CodeMirror
              value="// Write your code here"
              height="665px"
              lazyLoadMode={true}
              theme={myTheme}
              extensions={[javascript({ jsx: true })]}
              onChange={onChange}
            />
          </div>
          <div className={`${styles.output_space_area}`}>
            <LogsContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
