import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";

const PasswordRecovery = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [email, setEmail] = useState("");
  const [active, setActive] = useState(email.length > 0);
  const [canOpen, setCanOpen] = useState(false);
  const [error, setError] = useState("");
  const emailRegex = /\S+@\S+\.\S+/;
  const navigate = useNavigate();

  const handleEmailValidation = () => {
    if (emailRegex.test(email)) {
      //  mail link forwar.toDateString()
      setCanOpen(true);
      isOpen(true);
    } else {
      setError("pls fill valid email format");
      setTimeout(() => setError(""), 2000);
    }

    if (canOpen) {
      setTimeout(() => {
        setEmail("");
      }, 2000);
    } else {
      setEmail("");
    }
  };

  const handleNavigate = () => {
    navigate("/reset");
  };

  return (
    <>
      <Flex minH={"100vh"} align={"center"} justify={"center"}>
        <Stack
          spacing={4}
          w={"full"}
          maxW={"md"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
          my={12}
        >
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            <Link to="/recover">Forgot your password? </Link>
          </Heading>
          <Text
            fontSize={{ base: "sm", sm: "md" }}
            color={useColorModeValue("gray.800", "gray.400")}
          >
            You&apos;ll get an email with a reset link
          </Text>
          <FormControl id="email">
            <Input
              placeholder={
                error && error.length > 0 ? error : "your-email@example.com"
              }
              _placeholder={{ color: "gray.500" }}
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setActive(true);
              }}
            />
          </FormControl>
          <Stack spacing={6}>
            <Button
              bg={"blue.400"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
              onClick={() => {
                handleEmailValidation();
                if (canOpen) {
                  onOpen();
                }
              }}
            >
              Request Reset
            </Button>
          </Stack>
        </Stack>

        <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight="bold" mb="1rem">
                Trouble signing in? Resetting your password is easy. We have
                already send your attached email password reset link
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  onClose();
                  handleNavigate();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};

export default PasswordRecovery;
