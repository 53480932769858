import React, { useState, useEffect } from "react";
import CallToActionWithIllustration from "./CallToActionWithIllustration";
import GridBlurredBackdrop from "./GridBlurredBackDrop";
import SmallWithLogoLeft from "./../common/Footer/SmallWithLogoLeft";
import CodeSamples from "./CodeSamples";
import PricingPlan from "./PricingPlan";
import Features from "./Features";
import StupidTeam from "./StupidTeam";

const StartUp = () => {
  const [navLight, setNavLight] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 123) {
        setNavLight(true);
      } else {
        setNavLight(false);
      }
    });
  }, []);

  return (
    <div>
      <CallToActionWithIllustration navLight={navLight} />
      <CodeSamples />
      <Features />
      <StupidTeam />
      <PricingPlan />
      <GridBlurredBackdrop />
      <SmallWithLogoLeft />
    </div>
  );
};

export default StartUp;
